$(function() {
  $(window).on("resize", $winManager.onresizeSet);
  $(window).on("scroll", $winManager.onscrollSet);
  $winManager.onresizeSet();
  $winManager.onscrollSet();

  // スムーススクロール
  $('a[href^="#"]').click(function(){
    var speed = 600;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top;
    $("body,html").animate({scrollTop:position}, speed, "swing");
    return false;
  });

  //gnavi
  const $openKey = $('.gnavi-button');
  const $gnavi_area = $('.gnavi-area');
  const $gnavi_button = $('.gnavi-button-inner');
  const $body = $('body');

  $openKey.on('click', function (e) {
    e.stopPropagation();
    if (!$gnavi_area.hasClass('open') && !$gnavi_button.hasClass('open')) {
      $gnavi_area.addClass('open');
      $gnavi_button.addClass('open');
      searchcloseAction();
      $body.addClass('open');
    } else {
      closeAction();
    }
  });

  function closeAction() {
    if ($gnavi_area.hasClass('open') && $gnavi_button.hasClass('open')) {
      $gnavi_area.removeClass('open');
      $gnavi_button.removeClass('open');
      $body.removeClass('open');
    }
  }

  //search-area
  const $search_openKey = $('.header-search');
  const $search_area = $('.search-area');
  const $search_button = $('.header-search');

  $search_openKey.on('click', function (e) {
    e.stopPropagation();
    if (!$search_area.hasClass('open') && !$search_button.hasClass('open')) {
      $search_area.addClass('open');
      $search_button.addClass('open');
      closeAction();
      $body.addClass('open');
    } else {
      searchcloseAction();
    }
  });

  function searchcloseAction() {
    if ($search_area.hasClass('open') && $search_button.hasClass('open')) {
      $search_area.removeClass('open');
      $search_button.removeClass('open');
      $body.removeClass('open');
    }
  }

  $(document).on('click', 'body.open', function(){
    if ($gnavi_area.hasClass('open')) {
      closeAction();
    } else if ($search_area.hasClass('open')) {
      searchcloseAction();
    }
  });

  $gnavi_area.add($search_area).on('click', function(e){
    e.stopPropagation();
  });

  //new-icon
  const deleteCount = 10; // 何日後に削除するか - NEWマーク
  const today = new Date();
  let y = today.getYear();
  if (y < 1900) {
    y += 1900;
  }
  const m = today.getMonth() + 1;
  const d = today.getDate();
  const todayDate = new Date(y + "/" + m + "/" + d);

  $('.js-new-icon').each(function(){
    const thisDate = new Date($(this).data('time').replace(/-/g,"/"));
    const d = (todayDate - thisDate) / (1000 * 24 * 3600);
    if(deleteCount < d) {
      $(this).removeClass('_new-icon');
    }
  });

  //トップのもっと見るボタン
  $(".js-contents-toggle").each(function(){
    var firstCount = $(this).data("first-show-count");
    var count = $(this).data("show-count");
    var _target = $(this).find(".js-contents-toggle-elem");
    var _btn = $(this).find(".js-contents-toggle-btn");

    contentsToggle(firstCount, count, _target, _btn);
  })


  function contentsToggle(firstCount, count, _target, _btn) {
    var moreCount = 1;
    _target.addClass("is-hidden");
    var initCount = (firstCount)? firstCount : count;
    for(var i = 0; i <= initCount - 1; i++) {
      _target.eq(i).removeClass("is-hidden");
    }
    moreEndObserver();

    _btn.on("click", function(){
      moreCount++;
      var istart = (firstCount)? firstCount - 1 : 0;
      var icount = (firstCount)? firstCount + ((moreCount - 1) * count) - 1 : (moreCount * count) - 1;
      for(var i = istart; i <= icount; i++) {
        _target.eq(i).removeClass("is-hidden");
      }
      moreEndObserver();
    })

    function moreEndObserver() {
      if (_target.filter(".is-hidden").length <= 0) {
        _btn.addClass("is-hidden");
      } else {
        _btn.removeClass("is-hidden");
      }
    }
  }

  //記事のSNSシェアボタン生成
  const $target = $(".js-article-share");
  let url = document.URL;
  let title = $("title").text();
  let tw_title = $("meta[name='twitter:title']").attr("content")? $("meta[name='twitter:title']").attr("content") : title;
  //url = encodeURIComponent(url);
  tw_title = encodeURIComponent(tw_title);
  const html = '<ul class="sns-link-list"><li class="sns-link-item"><a href="http://twitter.com/intent/tweet?url=' + url + '&text=' + tw_title + '" class="link-img" target="_blank"><img src="/assets/images/common/sns_btn_head_x.png" alt=""></a></li><li class="sns-link-item"><a href="http://www.facebook.com/sharer.php?u=' + url + '" class="link-img" target="_blank"><img src="/assets/images/common/sns_btn_head_facebook.png" alt=""></a></li></ul>';
  $target.append(html);
});


//ページトップに戻るボタン
function pagetopFixed() {
  let margin = $winManager.pcFlag? 0 : 60 ;
  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const footHeight = $("footer").innerHeight();
  if (scrollHeight - scrollPosition + margin <= footHeight) {
    $(".pagetop").addClass("is-stop");
  } else {
    $(".pagetop").removeClass("is-stop");
  }
}

//トップページの背景固定
function topImgFixed() {
  const scrollHeight = $(document).height();
  const scrollPosition = $(window).height() + $(window).scrollTop();
  const footHeight = $("footer").innerHeight();
  if (scrollHeight - scrollPosition <= footHeight) {
    $(".bg-image").addClass("is-stop");
  } else {
    $(".bg-image").removeClass("is-stop");
  }
}

// Window Manager
var $winManager = {
  pcMaxWide: 1120,
  spWide: 640,
  pcFlag: null,
  w: 0,
  h: 0,
  ratio: 0,
  headerH: 100,
  scrollTop: 0,
  onresizeSet: function() {
    $winManager.w = window.innerWidth ? window.innerWidth : $(window).width();
    $winManager.h = window.innerHeight ? window.innerHeight : $(window).height();
    // $winManager.ratio = $winManager.w / $winManager.h;
    if ($winManager.w > $winManager.spWide) {
        $winManager.pcFlag = true;
    } else {
        $winManager.pcFlag = false;
    }
  },
  onscrollSet: function () {
    pagetopFixed();
    if($("body").hasClass("home")) topImgFixed();
  }
};